.about-container{
 
    background-color: white;
    margin-top: 100px;
    padding: 0px 20px;
    }
    
    .about-banner-container{
    
    
        display: flex;
     flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between; 
        padding: 25px 55px 25px 55px;
/* 
        border: 2px solid green;
        background-color: whitesmoke; */
    }
    .about-banner-container-A{
    
    
        display: flex;
     flex-direction:column-reverse;
        align-items: center;
        justify-content: space-between; 
        padding: 25px 55px 25px 55px;
/* 
        border: 2px solid green;
        background-color: whitesmoke; */
    }
    
    
    
    .about-bannerImage-container{
    
    position: absolute;
    
    
    left: 0%;
   
   
   
    overflow: hidden;
    
    }
    
    .about-image-section img{
    
       width: 660px;
       height:auto;
    }
    
   

    .lottie{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    
     /* background-color:black; */
  
  }
    .lottie-img{

      width: 40%;
      height: 40%;
    }
    @media screen and (max-width: 900px) {



      .about-banner-container{


          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between; 
          padding: 25px 20px;
      }
      .about-banner-container-A{
    
    
        display: flex;
     flex-direction:column-reverse;
        align-items: center;
        justify-content: space-between; 
        padding: 25px 20px;
/* 
        border: 2px solid green;
        background-color: whitesmoke; */
    }
      
      
      
      
      .about-bannerImage-container{
      
      position: absolute;
      
      right: 0%;
      top: 0%;
      width: 20%;
      overflow: hidden;
      
      }
      
      .about-image-section img{
      
         width: 260px;
         height:auto;
       
      }
      .lottie-img{

         width: 100%;
         height: 100%;
       }

  }