
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-left{
  text-align: left;
  
  }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

  
}


 
.background-img{

  z-index: 1;
}

.foreground-img{

  z-index: 2;


}
.frontground{
  z-index: 3;
}




.primary-heading-white{
  color: black;
  text-align: left;
}



.primary-text-white{
  color: black;
  text-align: left;

}

.primary-text-white-h{
  color: white;
  text-align: left;

}

.primary-heading{
text-align: center;
color: green;

}
.card-shodow{
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

 }


 /* teams card isze */

 .teams-card{
  /* width: 360px; */
  width: 350px;
  height: 200px;

 }


 
 @media screen and (max-width: 1025px) {
  .teams-card{
    /* width: 360px; */
    width: 280px;
    height: 275px;
    padding: 30px 20px;
  
   }
  

}
 @media screen and (max-width: 900px) {

  
 .teams-card{
  width: 100%;
  padding: 25px;
  height: 250px;

 }

   /* //card */

   .d-flex{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
 }

/* slide container
*/

