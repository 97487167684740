.text-black{
  color: black;
  text-align: center;
}

.client-container{

   
 
 /* background-color: whitesmoke; */
 overflow: hidden;
 }
 
 .client-banner-container{
 
 
     display: flex;
     flex-direction: row-reverse    ;
     align-items: flex-end;
     justify-content: space-between; 
     padding:  55px 25px ;

 }
 
 
 
 
 .client-bannerImage-container{
 
 position: absolute;
 
 left: 0%;
 /* top: 90%; */

 height: auto;
 overflow: hidden;
 
 }
 
 .client-image-section img{
 
 
  /* width: 500px; */
  width: 700px;
    /* height:auto; */
 }
 


 /* c;ient icon container  */
.client-icon-container{

    display: flex;
    flex-wrap: wrap;
    gap:15px ;
    padding: 25px;
    justify-content: center;
}

.client-icon-container img{

  width: 75px;
  height: auto;
  
}




@media screen and (max-width: 900px) {



    .client-banner-container{
 
 
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-between; 
       padding: 25px 20px;
   }
   
   
   
   
   .client-bannerImage-container{
   
   position: absolute;
   
   /* right: 0%; */
   left: -100%;
   /* top: 0%; */
   width: 200%;
   overflow: hidden;
   
   }
   
   .client-image-section img{
   
      width: 260px;
      height:auto;
    
   }



   .client-icon-container img{

    width: 55px;
    height: auto;
  }
  
   
 }
 
 