.footer-container {

    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 55px 55px 0px 55px;
    gap: 55px;
}
.footer-text-h-l {
    text-align: left;
    cursor: default;

}
.footer-text-l {
    text-align: left;
    cursor: pointer;
    color:  grey;
    font-weight: 500;

}
.footer-text-l:hover{

    color:  white;
  
}

.footer-top {

    display: flex;
    color: white;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 105px;

}


.footer-about {

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* gap:1px; */
}

.footer-services {

    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.footer-gettouch {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

/* footer icon text */

.footer-bottom {
    padding: 55px 0px 0px 0px;
    border-top: 1px solid rgba(211, 211, 211, 0.432);
}

.footer-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;

}

.footer-icon img {
    width: 260px;
}


.footer-icon-h {

    text-align: left;
    color: white;
}

.footer-icon-c {
    text-align: left;
    color: white;
}

.social-media {
    display: flex;
    gap: 25px;
}
.hyperlink{
    text-decoration: none;
    color:  grey;
}
.hyperlink:hover{
    text-decoration: none;
    color:  white;
}

.iconMarging{
    margin-right: 10px;
}
.services-footer-order{

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    /* grid-template-columns: 1fr ; */
}
/*  */


@media screen and (max-width: 900px) {

    .footer-top{

        display: flex;
        flex-direction: column;
        color: white;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: 105px;
    
    }
    .footer-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        padding: 0px 0px 25px 0px;
    }

    .services-footer-order{

        display: grid;
        grid-template-columns: 1fr ;
    }

}