.testimony-container{
    width: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 55px 35px;
    gap: 25px;
    overflow: hidden;
}
.testimony-container-client-card{
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider-con{
   

    width: 3000px;
}
@media screen and (max-width: 900px) {

    .slider-con{
   

        width: 300px;
    }

}