
:root{
    --career-text-weight:"100"
}
.careers-padding{

    padding: 30px;
    color: white;

    /* background-color: black; */
    /* background-color: rgba(0,0,0,0.4); */
}
.career-head{
    /* background-image: url('https://wallpaperaccess.com/full/6029948.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height:70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  filter:brightness(100%);
  backdrop-filter:none ; */


  position: relative;
  height:70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

}

.career-head::before{

    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-image: url('https://wallpaperaccess.com/full/6029948.jpg'); */
    background-image: url('../../assets/careers/careers1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    filter: brightness(50%);
    height:70vh;
   

    z-index: -1;
}
.textweight{
    font-weight: var(--career-text-weight);
     font-size: 18px;
}
.career-h-title{
    /* background-color: red; */
    /* padding: 10px; */
}

.careers-opening-container{
 
     background-color:white;
    
     padding: 70px 0px 70px 0px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
}

.career-border{

    padding: 25px;
    border: 2px solid white;
    width: fit-content;
    width: 85%;
   

}

.career-open-container{

    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Define three columns with equal width */
    gap: 25px;
    justify-items: center; /* Center items horizontally within each grid cell */
    align-items: center;
    border-width: 50px;
    padding: 25px 0;
  

}
@media screen and (max-width: 1185px) {


    .career-open-container{

        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Define three columns with equal width */
        gap: 25px;
        justify-items: center; /* Center items horizontally within each grid cell */
        align-items: center;
        border-width: 50px;
        padding: 25px 0;
      
    
    }

}
@media screen and (max-width: 990px) {

    .career-open-container{

        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Define three columns with equal width */
        gap: 25px;
        justify-items: center; /* Center items horizontally within each grid cell */
        align-items: center;
        border-width: 50px;
        padding: 25px 0;
      
    
    }
}