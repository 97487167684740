.testimon-card-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 250px;
  /* width: 250px; */
  padding: 15px;

}

.testimony-logo {

  width: 150px;
}



/* /// */

.testimony-card-shadow {
  --b: .5em;
  /* border width */
  --c: 3em;
  /* corner size */
  --r: 2em;
  /* corner rounding */
  position: relative;
  margin: 1em auto;
  border: solid var(--b) transparent;
  /* max-width: 23em; */
}

.testimony-card-shadow::before {
  position: absolute;
  z-index: -1;
  inset: calc(-1*var(--b));
  border: inherit;
  border-radius: var(--r);
  background: linear-gradient(rgb(23, 0, 156), rgb(0, 0, 0), rgb(2, 128, 23)) border-box;
  --corner:
    conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0) 0 0/ calc(100% - var(--c)) calc(100% - var(--c)) border-box;
  --inner: conic-gradient(red 0 0) padding-box;
  -webkit-mask: var(--corner), var(--inner);
  -webkit-mask-composite: source-out;
  mask: var(--corner) subtract, var(--inner);
  content: '';
}



.service-item:hover .service-content-icon img {
 /* background-color: red !important; */
 /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%); */
 /* filter: grayscale(100%) brightness(200%) contrast(200%); */
 /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(100deg) brightness(95%) contrast(80%); */
 /* background-color: white; Change black to white */

 filter: invert(100%) hue-rotate(242deg) grayscale(100%);

}



@media screen and (max-width: 900px) {

  .testimon-card-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
   height: 450px;



  }
}