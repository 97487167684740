

.services-container{

   
 
    background-color: white;
    overflow: hidden;
    padding: 30px;
    
    }
    
    .services-banner-container{
    
    
        display: flex;
      flex-direction: column;
        align-items: center;
        justify-content: center; 
        padding:55px;

    }
    
    
    
    
    .services-bannerImage-container{
    
    position: absolute;
    
    right: 0%;

    overflow: hidden;
    
    }
    
    .services-image-section img{
    
    
       width: 660px;
       height:auto;
    }
    
   
/* card conatiner */
   .service-card-container{

    display: flex;

    align-items: center;
    justify-content: center;
    gap: 25px;

    /* overflow: auto; */
    width: 100%;
    flex-wrap: wrap;
    padding: 55px;
    /* background-color: whitesmoke; */
    
}

 
  
.service-card-slider{

    width: 100%;
   }




   .service-card-container-m{

    display: flex;
    flex-wrap: wrap;
    /* width: 110%; */
    gap: 25px;
    padding: 25px;

    align-items: center;
    justify-content:space-evenly;
    /* background-color: red; */
   }
@keyframes scrollAnimation {
    0% ,100% {
        transform: translateX(0);
     
    }
    50% {
        transform: translateX(-750%);

    }
   
    
}


.slick-prev:before, .slick-next:before{

    color: black !important;
}

   @media screen and (max-width: 900px) {



    .services-container{

   
 
        background-color: white;
        overflow: hidden;
        /* padding: 10px  0px; */
        padding:   0px;
        
        
        }
 
        .services-banner-container{
        
        
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between; 
            padding: 25px 20px;
    
        }
        
        
        
        
        .services-bannerImage-container{
        
        position: absolute;
        
        /* inset: 0; */
        right:-100%;
        width: 200%;
        overflow: hidden;
        display: none;
        
        }

        .service-card-container > *{
    
   
            animation: none;
        
           }
        
        .services-image-section img{
        
        
           width: 260px;
           height:auto;
        }
        
       
    /* card conatiner */
       .service-card-container{
    
        display: flex;
     

        width: 100%;
        overflow: hidden;
       }


       .service-card-slider{

        /* width: 250px; */
        width: 250px;
       }
    
       .service-card-container-m{

        display: flex;
        flex-wrap: wrap;
        /* width: 80%; */
        gap: 25px;
        align-items: center;
        padding: 25px;
        /* justify-content:space-evenly; */
        /* background-color: red; */
       }

 }
 