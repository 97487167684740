.margin-zero {
    margin: 0;
}

.Careerscom-container {

    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    width: 350px;
    padding: 15px 25px;
    /* border-color: black; */
    border: 1px solid black;
}

.Careerscom-container:hover{

 background-color: whitesmoke;
   

}