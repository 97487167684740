
.team-text-align{
    text-align: center;
}
.team-container{

   
 
    background-color: white;
    overflow: hidden;
    padding: 55px 20px;
    
    }
    
    .team-banner-container{
    
    
        display: flex;
      
      
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between; 
        padding: 55px 55px 0 55px;
        gap: 25px;
    }
    
    
    
    
    .team-bannerImage-container{
    
    position: absolute;
    
    right: 0%;

    overflow: hidden;
    
    }
    
    .team-image-section img{
    
    
       width: 660px;
       height:auto;
    }
    
   
    /* card conatiner */
   .team-card-container{

 

        display: flex;

        align-items: center;
        justify-content: center;
        gap: 25px;
    
        /* overflow: auto; */
        /* width: 850px; */
        width:85vw;
        flex-wrap: wrap;
   }

   .team-crad-slider{

    width: 100%;
}


   
    @media screen and (max-width: 900px) {



        .team-banner-container{


            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between; 
            padding: 25px 20px;
            gap: 70px;
        }
        
        
        
        
        .team-bannerImage-container{
        
        position: absolute;
        
        right: 0%;
       
        width: 20%;
        overflow: hidden;
        
        }
        
        .team-image-section img{
           width: 260px;
           height:auto;
         
        }

      

         /* card conatiner */
         .team-card-container{
    
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 25px;
    
            width: 100%;
            /* overflow: hidden; */
           }
        
.team-crad-slider{

    width: 240px;
}


    }